.Navigation-Bar {
  background-color: #b4ff6e;
  border-bottom: 1vh solid #a9e362;
  line-height: 60%;
  padding-top: 0.5vh;
}

.Navigation-List {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 2vh;
}

.Navigation-Link {
  color: #41444e;
  font-weight: bold;
  text-decoration: none;
}

.Core-Contain {
  background-color: #1C1E23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Core-Link {
  color: #61dafb;
}

.Core-Page {
  padding-left: 5%;
  padding-right: 5%;
}

.Util-TextCenter {
  text-align: center;
}

.Util-DivFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Util-FlexHeight90 {
  height: 90vh;
}

.Util-DivFlexRow {
  flex-direction: row;
}

.Util-FlexWrap {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
}

