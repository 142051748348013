.Stream-VP {

}

.StreamMobile-Header {
    font-size: 5vh;
    text-align: center;
}

.StreamMobile-Subheader {
    font-size: 2.5vh;
    text-align: center;
}