@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap);
/* Define fonts */

body {
  margin: 0;
  font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1C1E23;
  color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Navigation-Bar {
  background-color: #b4ff6e;
  border-bottom: 1vh solid #a9e362;
  line-height: 60%;
  padding-top: 0.5vh;
}

.Navigation-List {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 2vh;
}

.Navigation-Link {
  color: #41444e;
  font-weight: bold;
  text-decoration: none;
}

.Core-Contain {
  background-color: #1C1E23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Core-Link {
  color: #61dafb;
}

.Core-Page {
  padding-left: 5%;
  padding-right: 5%;
}

.Util-TextCenter {
  text-align: center;
}

.Util-DivFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Util-FlexHeight90 {
  height: 90vh;
}

.Util-DivFlexRow {
  flex-direction: row;
}

.Util-FlexWrap {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
}


.Home-Greeting {
    font-size: 5vh;
    margin-bottom: 10%;
}

.Home-Description {
    height: 0;
    width: 100%;
    font-size: 1.5vh;
    margin-top: -70vh;
}

.Mobile-SecBreak {
    height: 0;
    width: 100%;
    font-size: 1.5vh;
    margin-top: -25vh;
}
.Stream-VP {

}

.StreamMobile-Header {
    font-size: 5vh;
    text-align: center;
}

.StreamMobile-Subheader {
    font-size: 2.5vh;
    text-align: center;
}
.E-ErrorCode {
    font-size: 10vh;
    text-align: center;
}

.E-ErrorHeadline {
    font-size: 4vh;
    margin-top: -5vh;
    text-align: center;
}

.E-ErrorMessage {
    font-size: 2vh;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}
