.Home-Greeting {
    font-size: 5vh;
    margin-bottom: 10%;
}

.Home-Description {
    height: 0;
    width: 100%;
    font-size: 1.5vh;
    margin-top: -70vh;
}

.Mobile-SecBreak {
    height: 0;
    width: 100%;
    font-size: 1.5vh;
    margin-top: -25vh;
}