.E-ErrorCode {
    font-size: 10vh;
    text-align: center;
}

.E-ErrorHeadline {
    font-size: 4vh;
    margin-top: -5vh;
    text-align: center;
}

.E-ErrorMessage {
    font-size: 2vh;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}